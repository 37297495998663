import {
	computed,
	getCurrentInstance,
	ref,
} from '@vue/composition-api';

import { NOTIFICATION_TYPE_RENDERLESS } from '@/store/builder/notifications';
import {
	useApi,
	TEMPLATE,
} from '@/use/useApi';

const hasLoadedCurrentTemplateStyles = ref(false);
const currentTemplateUneditedStyles = ref(null);

export const useUserStyles = () => {
	const {
		callApi,
		result: currentTemplate,
		hasLoaded,
	} = useApi();
	const currentTemplateId = getCurrentInstance()?.$store.state.website?.meta.template ?? {};

	const getCurrentTemplateStyles = async () => {
		if (hasLoadedCurrentTemplateStyles.value) {
			return;
		}

		await callApi(`${TEMPLATE}/${currentTemplateId}`, { method: 'get' }, { type: NOTIFICATION_TYPE_RENDERLESS });

		if (hasLoaded.value) {
			currentTemplateUneditedStyles.value = currentTemplate.value.styles;
		}

		hasLoadedCurrentTemplateStyles.value = true;
	};

	return {
		getCurrentTemplateStyles,
		currentTemplateUneditedStyles: computed(() => currentTemplateUneditedStyles.value),
		hasLoadedCurrentTemplateStyles: computed(() => hasLoadedCurrentTemplateStyles.value),
	};
};
